@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap');

.home-page {
    position: relative;
    height: auto;
}

.home-page .video-show {
    position: relative;
    height: calc(100vh);
}

.home-page #myVideo {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.home-page .video-show .cover-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    font-family: 'Merriweather Sans', sans-serif;
}

.home-page .video-show .cover-video .content-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
}

.home-page .video-show .cover-video h1 {
    font-size: 3em;
    text-align: center;
    color: white;
}

.home-page .video-show .cover-video p {
    font-size: 1.2em;
    text-align: center;
    color: white;
}

.home-page .information-tab {
    background: #080808;
    margin-top: -21px;
    padding-top: 50px;
    display: flex;
    padding-bottom: 60px;
    font-family: 'Merriweather Sans', sans-serif;
    flex-direction: row;
}

.home-page .information-tab .information-header {
    width: 150px;
    margin: 16px auto 50px auto;
    padding: 5px 10px 5px 10px;
    background: #25aae1;
    text-align: center;
    transform: skew(-20deg);
}


.home-page .information-tab .information-header .information-text {
    font-size: 1.5rem;
    transform: skew(20deg);
}

.home-page .information-tab .information-content .content {
    min-width: 60%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 50px;
}

.home-page .information-tab .information-content .content:nth-child(2) {
    margin-bottom: 0px;
}

.home-page .information-tab .information-content .content small {
    font-size: 0.8rem;
    color: gray;
}

.home-page .information-tab .information-content .content h2 {
    color: #25aae1;
    font-size: 1.4rem;
}

.home-page .information-tab .information-content .content p {
    color: white;
}

.home-page .information-tab .information-content .arrows {
    width: 40%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 20px;
}

.home-page .information-tab .information-content .arrows img {
    height: 25px;
    cursor: pointer;
    padding: 10px;
}

.home-page .information-tab .information-content .arrows p {
    color: white;
}

.home-page .information-tab .information-content .arrows #rotateArrow {
    transform: rotate(180deg);
}

.home-page .information-tab .information-content .arrows img:hover {
    opacity: 0.5;
}

.home-page .cennik-tab {
    background: white;
    padding-top: 25px;
    padding-bottom: 25px;
    font-family: 'Merriweather Sans', sans-serif;
}

.home-page .cennik-tab .cennik-header {
    width: 150px;
    margin: 16px auto 50px auto;
    padding: 5px 10px 5px 10px;
    background: #25aae1;
    text-align: center;
    transform: skew(-20deg);
}

.home-page .cennik-tab .cennik-header .cennik-text {
    font-size: 1.5rem;
    transform: skew(20deg);
    color: black;
}

.home-page .cennik-tab .cennik-table {
    margin: 10px auto 10px auto;
    max-width: 100%;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.home-page .cennik-tab .cennik-table table {
    position: relative;
    width: 40%;
    border-collapse: collapse;
    font-family: 'Merriweather Sans', sans-serif;
    margin: 20px;
    overflow-x: scroll;
    table-layout: fixed;
    word-break: break-all;
}

#widthSecondTable {
    width: 50%;
}

.home-page .cennik-tab .cennik-table table td, .home-page .cennik-tab .cennik-table table th {
    border: 1px solid none;
    padding: 8px;
    overflow: hidden;
    white-space: nowrap;
    word-wrap: break-word;
}

.home-page .cennik-tab .cennik-table table tr:nth-child(even){background-color: #f2f2f2;}
.home-page .cennik-tab .cennik-table table tr:nth-child(odd){background-color: lightgray;}
.home-page .cennik-tab .cennik-table table tr:hover {background-color: #ddd;}

.home-page .cennik-tab .cennik-table table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: black;
    color: #25aae1;
  }

  .home-page .cennik-tab .cennik-informacie {
    width: 40%;
    margin: 0 auto;
    position: relative;
  }

  .home-page .cennik-tab .cennik-informacie #info {
    color: gray;
    text-align: center;
    font-size: 11px;
  }

  .home-page .cennik-tab .cennik-informacie #important {
    color: #ff6174;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ff6174;
    border-radius: 10px;
    padding: 5px 0 5px 0;
    font-size: 10px;
    width: 80%;
    margin: 10px auto;
  }


  .home-page .cennik-tab .cennik-informacie #important img {
    height: 20px;
    padding-right: 10px;
  }

  .home-page .cennik-tab .arrows {
    width: 40%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    padding-top: 20px;
}

.home-page .cennik-tab .arrows img {
    height: 25px;
    cursor: pointer;
    padding: 10px;
}

.home-page .cennik-tab .arrows p {
    color: white;
}

.home-page .cennik-tab .arrows #rotateArrow {
    transform: rotate(180deg);
}

.home-page .cennik-tab .arrows img:hover {
    opacity: 0.5;
}

video::-webkit-media-controls {
    display: none;
}

#breakWord {
    word-wrap: break-word;
    max-width: 1px;
}

.home-page .cennik-tab {
    background: linear-gradient(0deg, rgba(17,17,17,1) 40%, rgba(28,28,28,1) 100%);

    background-blend-mode: multiply;
    padding-top: 25px;
    padding-bottom: 25px;
    font-family: 'Merriweather Sans', sans-serif;
}


.home-page .instruktor-tab {
    background: linear-gradient(0deg, rgba(17,17,17,1) 40%, rgba(28,28,28,1) 100%);
    padding-top: 25px;
    padding-bottom: 60px;
    font-family: 'Merriweather Sans', sans-serif;
}

.home-page .instruktor-tab .instruktor-header {
    width: 150px;
    margin: 16px auto 50px auto;
    padding: 5px 10px 5px 10px;
    background: #25aae1;
    text-align: center;
    transform: skew(-20deg);
}

.home-page .instruktor-tab .instruktor-header .instruktor-text {
    font-size: 1.5rem;
    transform: skew(20deg);
    color: black;
}

.home-page .instruktor-tab .instruktor-table {
    margin: 10px auto 10px auto;
    max-width: 100%;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.home-page .instruktor-tab .instruktor-table table {
    position: relative;
    width: 30%;
    border-collapse: collapse;
    font-family: 'Merriweather Sans', sans-serif;
    margin: 20px;
    word-wrap: break-all;
    overflow-y: visible;
    table-layout: auto;
}


.home-page .instruktor-tab .instruktor-table table td, .home-page .instruktor-tab .instruktor-table table th {
    border: 1px solid none;
    padding: 8px;
    overflow: hidden;
    white-space: nowrap;
}

.home-page .instruktor-tab .instruktor-table table tr:nth-child(even){background-color: #f2f2f2;}
.home-page .instruktor-tab .instruktor-table table tr:nth-child(odd){background-color: lightgray;}
.home-page .instruktor-tab .instruktor-table table tr:hover {background-color: #ddd;}

.home-page .instruktor-tab .instruktor-table table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
    background-color: #141414;
    color: #25aae1;
  }

  .home-page .instruktor-tab .instruktor-informacie {
    width: 40%;
    margin: 0 auto;
    position: relative;
  }

  .home-page .instruktor-tab .instruktor-informacie #info {
    color: gray;
    text-align: center;
    font-size: 11px;
  }

  .home-page .instruktor-tab .instruktor-informacie #important {
    color: #ff6174;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #ff6174;
    border-radius: 10px;
    padding: 5px 0 5px 0;
    font-size: 10px;
    width: 80%;
    margin: 10px auto;
  }


  .home-page .instruktor-tab .instruktor-informacie #important img {
    height: 20px;
    padding-right: 10px;
  }

  .home-page .partneri {
    background: linear-gradient(0deg, rgba(17,17,17,1) 40%, rgba(28,28,28,1) 100%);
    background-blend-mode: multiply;
    padding-top: 55px;
    padding-bottom: 60px;
    font-family: 'Merriweather Sans', sans-serif;
}

.home-page .lokacia {
    position: relative;
    min-height: 80vh;
    background: linear-gradient(0deg, rgba(17,17,17,1) 40%, rgba(28,28,28,1) 100%);
    padding-top: 25px;
    padding-bottom: 25px;
    font-family: 'Merriweather Sans', sans-serif;
}

.home-page .lokacia .lokacia-header {
    width: 150px;
    margin: 16px auto 50px auto;
    padding: 5px 10px 5px 10px;
    background: #25aae1;
    text-align: center;
    transform: skew(-20deg);
}

.home-page .lokacia #header {
    color: white;
    text-align: center;
}

.home-page .lokacia .lokacia-header .lokacia-text {
    font-size: 1.5rem;
    transform: skew(20deg);
    color: black;
}

.home-page .lokacia .google-maps {
    width: 80%;
    min-height: 640px;
    text-align: center;
    margin: 0 auto;
}

.home-page .partneri .partneri-header {
        width: 150px;
        margin: 16px auto 50px auto;
        padding: 5px 10px 5px 10px;
        background: #25aae1;
        text-align: center;
        transform: skew(-20deg);
}

.home-page .partneri .partneri-header .partneri-text {
    font-size: 1.5rem;
    transform: skew(20deg);
    color: black;
}

.home-page .partneri .partners-logos {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    margin: 0 auto;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.home-page .partneri .partners-logos img {
    height: 100px;
    border-radius: 50%;
    margin: 10px;
}

.home-page .podujatia {
    min-height: 100vh;
    background: linear-gradient(0deg, rgba(17,17,17,1) 40%, rgba(28,28,28,1) 100%);
    padding-top: 25px;
    padding-bottom: 25px;
    font-family: 'Merriweather Sans', sans-serif;
}

.home-page .podujatia .podujatia-header {
    width: 150px;
    margin: 16px auto 50px auto;
    padding: 5px 10px 5px 10px;
    background: #25aae1;
    text-align: center;
    transform: skew(-20deg);
}

.home-page .podujatia #header {
    color: white;
    text-align: center;
}

.home-page .podujatia .podujatia-header .podujatia-text {
    font-size: 1.5rem;
    transform: skew(20deg);
    color: black;
}

.home-page .podujatia .podujatia-mainflex {
    position: relative;
    display: flex;
    margin: 30px;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    flex-wrap: nowrap;
}

.home-page .podujatia .podujatia-mainflex .podujatia-mainflex-new {
    max-width: 80%;
}

.home-page .podujatia .podujatia-mainflex .podujatia-mainflex-new h2 {
    color: #25aae1;
    text-align: center;
}

.home-page .podujatia .podujatia-mainflex .podujatia-mainflex-new .podujatie-item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px;
    flex-direction: column;
    flex-wrap: wrap;
    cursor: pointer;
    justify-content: space-between;
    padding-bottom: 20px;
}

.home-page .podujatia .podujatia-mainflex .podujatia-mainflex-new .podujatie-item:hover {
    opacity: .8;
}

.home-page .podujatia .podujatia-mainflex .podujatia-mainflex-new .podujatie-item img {
    width: 450px;
    border-radius: 20px;
}

.home-page .podujatia .podujatia-mainflex .podujatia-mainflex-new .podujatie-item .podujatieItem-info {
    position: relative;
    text-align: center;
}

.home-page .podujatia .podujatia-mainflex .podujatia-mainflex-new .podujatie-item .podujatieItem-info h2 {
    color: white;
}


.home-page .podujatia .podujatia-mainflex .podujatia-mainflex-new .podujatie-item .podujatieItem-info p {
    color: white;
    width: 400px;
    text-align: center;
    margin: 0 auto;
}

.home-page .podujatia .podujatia-mainflex .podujatia-mainflex-old {
    max-width: 80%;
}

.home-page .podujatia .podujatia-mainflex .podujatia-mainflex-old h2 {
    color: #25aae1;
    text-align: center;
}

.home-page .podujatia .podujatia-mainflex .podujatia-mainflex-old .podujatie-item {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 15px;
    flex-direction: column;
    flex-wrap: wrap;
    cursor: pointer;
    justify-content: space-between;
    padding-bottom: 20px;
}

.home-page .podujatia .podujatia-mainflex .podujatia-mainflex-old .podujatie-item:hover {
    opacity: .8;
}

.home-page .podujatia .podujatia-mainflex .podujatia-mainflex-old img {
    width: 450px;
    border-radius: 20px;
}

.home-page .podujatia .podujatia-mainflex .podujatia-mainflex-old .podujatieItem-info {
    position: relative;
    text-align: center;
}

.home-page .podujatia .podujatia-mainflex .podujatia-mainflex-old .podujatieItem-info h2 {
    color: white;
}

.home-page .podujatia .podujatia-mainflex .podujatia-mainflex-old .podujatieItem-info p {
    color: white;
    width: 400px;
    text-align: center;
    margin: 0 auto;
}

.home-page .kontakt {
    position: relative;
    min-height: auto;
    background: linear-gradient(0deg, rgba(17,17,17,1) 40%, rgba(28,28,28,1) 100%);
    padding-top: 25px;
    padding-bottom: 50px;
    font-family: 'Merriweather Sans', sans-serif;
}

.home-page .kontakt .kontakt-header {
    width: 150px;
    margin: 16px auto 50px auto;
    padding: 5px 10px 5px 10px;
    background: #25aae1;
    text-align: center;
    transform: skew(-20deg);
}

.home-page .kontakt .kontakt-header .kontakt-text {
    font-size: 1.5rem;
    transform: skew(20deg);
    color: black;
}

#root > div > div.home-page > div.kontakt > div.contant-content {
    position: relative;
    color: white;
    text-align: center;
}

#root > div > div.home-page > div.kontakt > div.contant-content > div > img {
    width: 30px;
    margin: 10px;
}

#root > div > div.home-page > div.kontakt > div.contant-content > div.contact-telephone {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -15px;
}

#root > div > div.home-page > div.kontakt > div.contant-content > div.contact-telephone a {
color: white;
text-decoration: none;
}

#root > div > div.home-page > div.video-show > div.dolezite {
    position: absolute;
    width: 100%;
    min-height: 100px;
    padding-bottom: 15px;
    font-family: 'Merriweather Sans', sans-serif;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
}

#root > div > div.home-page > div.video-show > div.dolezite > h2 {
    color: #25aae1;
    text-align: center;
}

#root > div > div.home-page > div.video-show > div.dolezite > p {
    color: white;
    text-align: center;
    width: 80%;
    margin: 0 auto;
}

#root > div > div.home-page > div.video-show > div.weather {
    position: absolute;
    left: 0;
    top: 0;
    min-width: 200px;
    min-height: 100px;
    background: rgba(0,0,0,0.5);
    margin-top: 80px;
    font-family: 'Merriweather Sans', sans-serif;
    padding-bottom: 10px;
}

#root > div > div.home-page > div.video-show > div.weather > h2 {
    font-size: 14px;
    color: #25aae1;
    text-align: center;
}

#root > div > div.home-page > div.video-show > div.weather > div.weather-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

#root > div > div.home-page > div.video-show > div.weather > div.weather-item > p {
    font-size: 12px;
    margin: 2.5px;
}

#root > div > div.home-page > div.video-show > div.weather > div.weather-item > img {
    width: 15px;
    padding-right: 10px;
}


@media only screen and (max-width: 600px) {
    
    .home-page .podujatia .podujatia-mainflex {
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
    }

    .home-page .podujatia .podujatia-mainflex .podujatia-mainflex-new .podujatie-item {
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
    }

    .home-page .podujatia .podujatia-mainflex .podujatia-mainflex-new {
        max-width: 100%;
        min-width: 100%;
    }

    .home-page .podujatia .podujatia-mainflex .podujatia-mainflex-new .podujatie-item .podujatieItem-info {
        margin: 0;
    }

    .home-page .podujatia .podujatia-mainflex .podujatia-mainflex-old .podujatieItem-info {
        margin: 0;
    }

    .home-page .podujatia .podujatia-mainflex .podujatia-mainflex-old .podujatie-item {
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        cursor: pointer;
    }
    .home-page .podujatia .podujatia-mainflex .podujatia-mainflex-old {
        max-width: 100%;
        min-width: 100%;
    }

    .home-page .podujatia .podujatia-mainflex {
        flex-direction: column;
        align-items: center;
    }

    .home-page .information-tab {
        flex-direction: column;
    }

    .home-page .partneri .partners-logos {
        width: 70%;
    }

    .home-page .lokacia .google-maps {
        width: 100%;
    }

    .home-page .video-show .cover-video .content-box {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80%;
        transform: translate(-50%, -50%);
    }

    .home-page .cennik-tab .cennik-informacie #important {
        width: 100%;
    }

    .home-page .video-show .cover-video h1 {
        font-size: 30px;
    }

    .home-page .video-show .cover-video p {
        font-size: 12px;
    }

    .home-page .information-tab .information-content .content {
        width: 90%;
    }

    .home-page .information-tab .information-content .arrows {
        padding-top: 20px;
    }

    .home-page .cennik-tab .cennik-informacie {
        width: 80%;
    }

    .home-page .cennik-tab .cennik-header {
        margin: 16px auto 25px auto;
    }

    .home-page .cennik-tab .cennik-table table {
        width: 100%;
        table-layout: fixed;
        word-wrap: break-word;
        margin: 0;
        overflow-x: scroll;
    }

    #widthSecondTable {
        width: 100%;
    }

    .home-page .instruktor-tab .instruktor-informacie {
        width: 80%;
    }

    .home-page .instruktor-tab .instruktor-header {
        margin: 16px auto 25px auto;
    }

    .home-page .instruktor-tab .instruktor-table table {
        width: 100%;
    }

    .home-page .instruktor-tab .instruktor-table table {
        width: 100%;
        table-layout: fixed;
    }

    #root > div > div.home-page > div.podujatia > div.podujatia-mainflex > div.podujatia-mainflex-new > div > img {
        width: 300px;
    }

    #root > div > div.home-page > div.podujatia > div.podujatia-mainflex > div.podujatia-mainflex-old > div > img {
        width: 300px;
    }
    
}