@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap');


.login-page {
    min-height: 80vh;
    width: 100%;
    font-family: 'Merriweather Sans', sans-serif;
    background: #1a1a1a;
    padding-top: 100px;
}

.login-page .header {
    text-align: center;
}

.login-page .header h1 {
    color: #FFC93C;
}

.login-page .login-form {
    border-radius: 5px;
    background-color: rgba(15,15,15,1);
    padding: 30px;
    width: 35%;
    margin: 0 auto;
    border-radius: 20px;
}

.login-page .login-form label {
    color: #FFC93C;
}

.login-page .login-form input[type=text], select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .login-page .login-form input[type=password]{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }
  
  .login-page .login-form input[type=submit] {
    width: 50%;
    background-color: #FFC93C;
    color: black;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    
  }
  
  .login-page .login-form input[type=submit]:hover {
    opacity: 0.5;
  }
  
  .login-page .login-form p {
    color: white;
    font-size: 13px;
    text-align: left;
  }

  @media only screen and (max-width: 600px) {
    .login-page .login-form {
        width: 80%;
    }
  }