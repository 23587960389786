@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap');

.archive {
    position: relative;
    min-height: 100vh;
    background: #1a1a1a;
    font-family: 'Merriweather Sans', sans-serif;
    padding-top: 80px;
}

.archive h1 {
    text-align: center;
    color: white;
    padding-top: 10px;
}

.archive .events {
    position: relative;
    display: flex;
    width: 90%;
    margin: 0 auto;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: center;
}

.archive .events .event-item {
    position: relative;
    margin: 15px;
    text-align: center;
}

.archive .events .event-item img {
    width: 500px;
    border-radius: 20px;
}


.archive .events .event-item .archive-item-info {
    color: white;
}

.archive .events .event-item .archive-item-info p {
    width: 50%;
    margin: 10px auto;
}

.archive .events .event-item .archive-item-info button {
    background: #25aae1;
    border: 0px;
    padding: 8px 15px;
    cursor: pointer;
    margin-top: 5px;
    font-family: 'Merriweather Sans', sans-serif;
    color: white;
}

.archive .events .event-item .archive-item-info button:hover {
    opacity: 0.5;
}

.archive-item {
    position: relative;
    min-height: 100vh;
    background:#1a1a1a;
    padding-top: 80px;
    font-family: 'Merriweather Sans', sans-serif;
}

.archive-item h1 {
    color: white;
    text-align: center;
    padding-top: 15px;
}

.archive-item .archive-item-desc {
    width: 50%;
    text-align: center;
    margin: 40px auto -20px auto;
    padding-bottom: 25px;
    color: white;
}

.archive-item .photos-showcase {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: column;
}

.archive-item .photos-showcase img {
    width: 600px;
    border-radius: 10px;
    margin: 15px;
}

@media only screen and (max-width: 600px) {
    .archive .events .event-item img {
        width: 350px;
        border-radius: 20px;
    }

    .archive-item .archive-item-desc {
        width: 85%;
    }

    .archive-item .photos-showcase {
        flex-wrap: wrap;
    }

    .archive-item .photos-showcase img {
        width: 300px;
    }
}