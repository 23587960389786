@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap');

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: rgba(0, 0, 0, 1);
  min-height: 80px;
  font-family: 'Merriweather Sans', sans-serif;
  z-index: 1100;
  width: calc(100% - 400px);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .8);
}

.navbar .logo img {
  height: 60px;
}

.navbar .logo {
  flex: 5 2 auto;
  padding-left: 50px;
}

.navbar .logo #newHeight {
  height: 60px;
}

.navbar #newPadding {
  padding-left: 20px;
}

.navbar ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 0;
  flex: 1 2 auto;
}

.navbar ul .dropdown {
  position: absolute;
  width: 25vw;
  top: 80px;
  background: rgba(15, 15, 15, .95);
  min-height: 150px;
}

.navbar ul .dropdown .item {
  position: relative;
  padding: 10px 0 10px 20px;
  color: white;
  background: rgba(15, 15, 15, .95);
  cursor: pointer;
  z-index: 500;
}

.navbar ul .dropdown .item:hover {
  opacity: .5;
}

.navbar ul li {
  position: relative;
  list-style-type: none;
  padding-right: 30px;
}

.navbar ul li a {
  color: white;
  text-decoration: none;
  transition: all ease-in-out 0.2s;
  cursor: pointer;
}

.navbar ul li a:hover {
  color: #25aae1;
}

.sidenav {
  height: 100%;
  /* Full-height: remove this if you want "auto" height */
  width: 400px;
  /* Set the width of the sidebar */
  position: fixed;
  /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1;
  /* Stay on top */
  top: 0;
  /* Stay at the top */
  right: 0;
  background-color: #111;
  /* Black */
  overflow-x: hidden;
  /* Disable horizontal scroll */
  padding-top: 20px;
}

.sidenav a {
  padding: 6px 8px 6px 16px;
  text-decoration: none;
  font-size: 20px;
  color: #818181;
  display: block;
  text-align: center;
}

.sidenav button {
  margin-left: 15px;
  border-radius: 50%;
  padding: 5px 10px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
}

.sidenav button #hide {
  color: white;
  font-size: 50px;
}

/* When you mouse over the navigation links, change their color */
.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .bot {
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 100px;
  text-align: center;
}

.sidenav .bot img {
  height: 60px;
}

.sidenav .daily-info {
  position: relative;
  width: 80%;
  margin: 30px auto 10px auto;
  display: flex;
  align-items: center;
  border: 1px solid aqua;
  border-radius: 10px;
  padding: 10px;
  justify-content: space-between;
}

.sidenav .daily-info img {
  padding-right: 15px;
}

.sidenav .daily-header {
  position: relative;
  margin: 0 auto;
  width: calc(80% + 15px);
  color: aqua;
  margin-bottom: -15px;
  margin-top: 25px;
}

.sidenav .zaznamy-kamier {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.sidenav .zaznamy-kamier img {
  height: 100px;
  border-radius: 20px;
}

.sidenav .daily-info .text {
  color: aqua;
  text-align: center;
}

.sidenav .daily-info img {
  height: 20px;
}

.sidenav .styled-table {
  padding-left: 30px;
  color: white;
  font-size: 13px;
  text-align: left;
}

#root>div>div.navbar>div.zoom {
  position: absolute;
  width: 150vw;
  height: 200vh;
  background: rgba(0, 0, 0, 0.8);
  z-index: 55;
  text-align: center;
}

#root>div>div.navbar>div.zoom>img {
  position: relative;
  width: 50%;
  z-index: 55;
  top: 50%;
  left: 8%;
}

.otvorenie tr>td:nth-child(2) {
  padding-left: 10px;
  padding-right: 10px;
}


@media only screen and (max-width: 600px) {
  .sidenav {
    width: 100vw;
    /* Set the width of the sidebar */
  }

  .navbar {
    width: 100%;
  }

}