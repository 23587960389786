@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap');

.footer {
    position: relative;
    width: 100%;
    min-height: 200px;
    background: linear-gradient(90deg, rgba(17,17,17,1) 50%, rgba(22,22,22,1) 100%);
    font-family: 'Merriweather Sans', sans-serif;
}

.footer .content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 200px;
    width: 100%;
}

.footer .content .item:first-child {
    flex: 1 6 auto;
    text-align: center;
}


.footer .content .item:nth-child(2) {
    flex: 6 1 auto;
    display: flex;
    text-align: center;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    padding-right: 60px;
    right: 0;
}

.footer .content .item img {
    height: 100px;
    text-align: center;
}

.footer .content .item:nth-child(2) .subitem {
    position: relative;
    color: white;
}

.footer .content .item:nth-child(2) .subitem:nth-child(1) {
    padding-right: 50px;
}

.footer .content .item:nth-child(2) .subitem small {
    font-size: 10px;
}

.footer .content .item:nth-child(2) .subitem img {
    height: 30px;
    padding: 5px;
}


div.footer > div > div:nth-child(2) > div:nth-child(1) > img:nth-child(2) {
    padding-right: 0;
}

@media only screen and (max-width: 600px) { 
    .footer .content .item:nth-child(2) .subitem img {
        height: 25px;
    }

    .footer .content .item img {
        height: 70px;
        padding-left: 10px;
    }

    .footer .content .item:nth-child(2) .subitem {
        position: relative;
        color: white;
    }

    .footer .content .item:nth-child(2) { 
        padding-right: 10px;
    }
}