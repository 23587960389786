@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap');

.kamery {
    position: relative;
    padding-top: 80px;
    min-height: 100vh;
    background: linear-gradient(0deg, rgba(17,17,17,1) 40%, rgba(28,28,28,1) 100%);
    font-family: 'Merriweather Sans', sans-serif;
    text-align: center;
}

.kamery img {
    position: relative;
    width: 80%;
    margin: 20px auto 20px auto;
    border-radius: 20px;
}