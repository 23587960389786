@import url('https://fonts.googleapis.com/css2?family=Merriweather+Sans&display=swap');

.dashboard-page {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background: #1a1a1a;
    display: flex;
    justify-content: center;
    font-family: 'Merriweather Sans', sans-serif;
    flex-wrap: wrap;
    flex-direction: row;
    padding-top: 80px;
}

.dashboard-page .dash-bar {
    flex-basis: 300px;
    background: black;
    color: white;
}

.dashboard-page .dash-bar .header {
    background: white;
    padding: 5px;
    text-align: center;
    color: black;
}

.dashboard-page .dash-bar .bar-option {
    text-align: center;
    background: black;
    padding: 3px;
    cursor: pointer;
}

.dashboard-page .dash-bar .bar-option:hover {
    opacity: 0.5;
    background: rgba(255,255,255,0.1);
}

.dashboard-page .content {
    position: relative;
    flex-basis: calc(100% - 300px);
}

.dashboard-page .content .header{
    padding: 5px;
    text-align: center;
    color: white;
}

.dashboard-page .content .information {
    position: relative;
    border: 1px solid lightblue;
    padding: 5px 20px 5px 20px;
    color: #05779c;
    width: 500px;
    margin: 0 auto;
    border-radius: 20px;
    word-wrap: break-word;
    background: #add8e6;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.dashboard-page .content .information img {
    position: relative;
    height: 25px;
    padding-right: 20px;
}

.dashboard-page .content .login-form {
    border-radius: 5px;
    padding: 30px;
    width: 50%;
    margin: 0 auto;
    border-radius: 20px;
}

.dashboard-page .content .login-form label {
    color: #FFC93C;
}

.dashboard-page .content .login-form input[type=text], textarea , select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .dashboard-page .content .login-form input[type=password] {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
  }

  .dashboard-page .content .login-form textarea {
    min-height: 300px;
  }
  
  .dashboard-page .content .login-form input[type=submit] {
    width: 50%;
    background-color: #FFC93C;
    color: black;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    
  }
  
  .dashboard-page .content .login-form input[type=submit]:hover {
    opacity: 0.5;
  }
  
  .dashboard-page .content .login-form p {
    color: white;
    font-size: 13px;
    text-align: left;
  }

  .dashboard-page .content #events-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 50%;
  }


  .dashboard-page .content #events-table td, dashboard-page .content #events-table th {
    padding: 10px;
  }
  
  .dashboard-page .content #events-table tr {background-color: white}

  .dashboard-page .content #events-table tr td {cursor: pointer}
  
  .dashboard-page .content #events-table tr:hover {background-color: #ddd;}
  
  .dashboard-page .content #events-table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #25aae1;
    color: white;
  }

  @media only screen and (max-width: 600px) {
    .dashboard-page .content .login-form {
        width: 80%;
    }

    .dashboard-page {
        flex-direction: column;
        height: auto;
    }

    .dashboard-page .content .information {
        width: 300px;
    }
  }