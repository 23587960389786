body {
    margin: 0;
}

.App {
    position: relative;
    box-sizing: border-box;
}

.App .screen {
    position: relative;
    display: flex;
}

.App .screen .left-side {
    position: relative;
    max-width: 70%;
}
